import { createStore } from 'vuex'
import { Sync } from '@/api/user.js'

const store = createStore({
  state: {
    auth: {
      isAuthenticated: false,
      loginUrl: '/'
    },
    user: {
      id: 0,
      name: '',
      email: '',
      nickname: '',
      image: '',
      position: []
    },
    personal: {
      favorites: [],
      owner: []
    },
    map: {
      zoom: 6,
      lat: 54,
      lng: 10
    },
    spots: [],
    filter: '',
    changelog: '',
    settings: {
      sync: false
    }
  },

  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    },

    authChange(state, loggedIn) {
      state.auth.isAuthenticated = loggedIn
    },

    createUser(state, properties) {
      for (let prop in properties) {
        switch (prop) {
          case 'id':
            state.user.id = properties[prop]
            break;
          case 'name':
            state.user.name = properties[prop]
            break;
          case 'nickname':
            state.user.nickname = properties[prop]
            break;
          default:
            console.warn(`User property ${prop} can not be stored`)
        }
      }
    },

    updateUserData: (state, data) => {
      for (let prop in data) {
        switch (prop) {
          case 'name':
            state.user.name = data.name
            break
          case 'image':
            state.user.image = data.image
            break
          case 'email':
            state.user.email = data.email
            break
          case 'favorites':
            // Arraies in http response are of type string
            if (typeof data.favorites === 'string') {
              state.personal.favorites = JSON.parse(data.favorites)
            } else {
              state.personal.favorites = data.favorites
            }
            break
          case 'owner':
            // Arraies in http response are of type string
            if (typeof data.owner === 'string') {
              state.personal.owner = JSON.parse(data.owner)
            } else {
              state.personal.owner = data.owner
            }
            break
          case 'settings':
            state.settings = data.settings
            break
        }
      }
    },

    cleanUser(state) {
      for (let prop in state.user) {
        if (['name', 'email', 'nickname', 'image'].includes(prop)) {
          state.user[prop] = ''
        } else if (prop == 'id') {
          state.user.id = 0
        } else {
          state.position = []
        }
      }
      for (let prop in state.personal) {
        state.personal[prop] = []
      }
      state.filter = ''
      state.settings = {}
      state.map = { zoom: 6, lat: 54, lng: 10 }
    },

    updateUserPosition: (state, position) => {
      // Arraies in http response are of type string
      if (typeof position === 'string') {
        state.user.position = JSON.parse(position)
      } else {
        state.user.position = position
      }
    },

    updateMapstate: (state, data) => {
      for (let prop in data) {
        switch (prop) {
          case 'zoom':
            state.map.zoom = data.zoom;
            break;
          case 'center':
            state.map.lat = data.center.lat;
            state.map.lng = data.center.lng;
        }
      }
    },
    setFilter: (state, data) => {
      state.filter = data;
    },

    saveSpots: (state, spots) => {
      // Find local stored spots
      const localSpots = state.spots.filter(spot => spot.properties.id < 0)

      // Override all spots with spots in server response
      state.spots = spots
      // Add temporay stored local spots to list 
      state.spots.push(...localSpots)
    },

    menuChange: (state, data) => {
      state.menuOpen = data
    },

    createSpot: (state, data) => {
      state.spots.push(data)
    },

    updateSpot: (state, spot) => {
      // Test if to update a new or existing spot.
      // New: {data: data, new: id}; Existing: {data}
      // A new spot will get a final ID different to the current one.
      const [spotId, data] = spot.new !== undefined ? [spot.new, spot.data] : [spot.properties.id, spot]

      for (let i = 0; i < state.spots.length; i++) {
        if (state.spots[i].properties.id == spotId) {
          // To preserve reactivity it is neccessary to keep arrays and avoid to override
          // with a new array. Modify arrays by index like array[2] also not work.
          // Answer: Update the spot in a new object, delete the origin spot and add the "old" updated
          // spot to the array
          // Copy spot
          const updatedSpot = Object.assign({}, state.spots[i])

          // Update spot
          Object.assign(updatedSpot.properties, data.properties)

          // Delete origin and add updated spot as new "origin"
          // Note: state.spots.push(updatedSpot) without setTimeout() not work ¯\_(ツ)_/¯
          //       state.spots.splice(i, 1, updatedSpot) should work but also doesn't
          //       Change order (push -> splice) will result in unexpected behavior
          state.spots.splice(i, 1)
          setTimeout(() => {
            state.spots.push(updatedSpot)
          }, 5)


          // stop searching. Updated spot is found 
          break;
        }
      }
    },

    deleteSpot: (state, id) => {
      for (let i = 0; i < state.spots.length; i++) {
        if (state.spots[i].properties.id == id) {
          state.spots.splice(i, 1);
          break;
        }
      }
    }
  },


  actions: {
    authChange({ commit, dispatch }, loggedIn) {
      commit('authChange', loggedIn)
      if (loggedIn) {
        dispatch('loadSpots')
      }
    },

    async updateUser({ commit }, data) {
      commit('updateUserData', data);
    },

    async deleteUser({ commit }) {
      commit('cleanUser')
    },

    updateMapstate: ({ commit }, data) => {
      commit('updateMapstate', data);
    },

    async updateUserPosition({ commit }, data) {
      let position = []
      // from map view
      if ('lat' in data) position = [data.lng, data.lat]
      // from list view
      if ('latitude' in data) position = [data.longitude, data.latitude]
      commit('updateUserPosition', position);
    },

    async loadSpots({ commit }) {
      fetch(`${process.env.VUE_APP_API_URL}/spot`, {
        method: "GET",
        credentials: 'same-origin'
      })
        .then(res => { return res.json() })
        .then(res => {
          let spots = res.features.map(feature => {
            // switch lng/lat to lat/lng for marker syntax
            feature.geometry.coordinates.reverse()
            return feature
          })
          commit('saveSpots', spots);
        });
    },

    async createSpot({ commit }, data) {
      // switch lng/lat to lat/lng for marker syntax
      data.geometry.coordinates.reverse()
      commit('createSpot', data)
    },

    async updateSpot({ commit }, data) {
      commit('updateSpot', data)
    }
  },


  getters: {
    authState: state => state.auth.isAuthenticated,
    loginpage: state => state.auth.loginUrl,
    userbasic: state => state.user,
    syncState: state => state.settings.sync,
    mapstate: state => state.map,
    menuOpen: state => state.menuOpen,
    changelog: state => state.changelog,

    mapItems: state => {
      let items = state.spots.map(feature => {
        // mark favorites
        //let found = state.favorites.indexOf(feature.properties.id);
        //feature.properties.favorite = found != -1 ? true : false;

        return {
          geometry: feature.geometry,
          properties: {
            id: feature.properties.id,
            favorite: feature.properties.favorite,
            name: feature.properties.name,
            land: feature.properties.land,

            // link to Gmaps
            //route: 'https://www.google.com/maps/dir/?api=1&destination='
            //  + feature.geometry.coordinates[0] + ',' + feature.geometry.coordinates[1]
          }
        };
      })
      return items
    },

    detailsSpot: state => id => {
      for (let feature of state.spots) {
        if (feature.properties.id == id) {
          const spot = JSON.parse(JSON.stringify(feature));
          // open/close details dialog
          //let found = state.showDetails.indexOf(feature.properties.id);
          //feature.properties._showDetails = found != -1 ? true : false;

          // mark favorites
          //found = state.favorites.indexOf(feature.properties.id);
          //feature.properties.favorite = found != -1 ? true : false;

          // interpret missing properties as unkown porperties
          let optionalProps = ['fee', 'costs', 'night', 'parking', 'toilette', 'seaview',
            'heightlimited', 'heightlimit', 'pics']
          for (let prop of optionalProps) {
            if (!(prop in feature.properties)) spot.properties[prop] = 'unknown';
          }
          if (!('notes' in feature.properties)) spot.properties.notes = '';


          if (spot.properties.pics !== 'unknown') {
            spot.properties.pics = feature.properties.pics.map(url =>
              location.origin + process.env.VUE_APP_PUBLIC_PATH + '/' + url
            )
          }
          // link to Gmaps
          spot.properties.route = 'https://www.google.com/maps/dir/?api=1&destination='
            + feature.geometry.coordinates[0] + ',' + feature.geometry.coordinates[1];
          // link to share
          spot.properties.share = `/spot/${feature.properties.id}`;

          return spot;
        }
      }
    },

    listItems: state => {
      let items = state.spots.map(feature => {
        // open/close details dialog
        //let found = state.showDetails.indexOf(feature.properties.id);
        //feature.properties._showDetails = found != -1 ? true : false;

        // mark favorites
        //found = state.favorites.indexOf(feature.properties.id);
        //feature.properties.favorite = found != -1 ? true : false;

        // interpret empty as unkown porperty
        if (feature.properties.fee == '') feature.properties.fee = 'unknown';
        if (feature.properties.toilette == '') feature.properties.toilette = 'unknown';

        // calculate distance
        let lat1 = state.user.position[1];
        let lon1 = state.user.position[0];
        let lat2 = feature.geometry.coordinates[0]
        let lon2 = feature.geometry.coordinates[1]

        // https://www.geodatasource.com/developers/javascript
        if ((lat1 == lat2) && (lon1 == lon2)) {
          feature.properties.distance = 0;
        }
        else {
          var radlat1 = Math.PI * lat1 / 180;
          var radlat2 = Math.PI * lat2 / 180;
          var theta = lon1 - lon2;
          var radtheta = Math.PI * theta / 180;
          var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          if (dist > 1) {
            dist = 1;
          }
          dist = Math.acos(dist);
          dist = dist * 180 / Math.PI;
          dist = dist * 60 * 1.1515;
          // return in kilometer
          feature.properties.distance = Math.round(dist * 1.609344);
        }

        // link to Gmaps
        feature.properties.route = 'https://www.google.com/maps/dir/?api=1&destination='
          + feature.geometry.coordinates[0] + ',' + feature.geometry.coordinates[1];

        return feature.properties;
      })
      return items
    },

    newSpotId: state => {
      // All new spots which are not sync with the database have a negative number. So the new spot also
      // will have a negative number. Sync the database is a second step
      // If all spots are in sync the latest spot has a positive number.
      const ids = []
      state.spots.forEach(feature => {
        ids.push(feature.properties.id)
      })

      if (Math.min(...ids) < 0) {
        return Math.min(...ids) - 1

      } else {
        return -1
      }
    },

    ownSpots: state => {
      const items = [];
      for (let feature of state.spots) {
        // find owned spots
        if (state.personal.owner.includes(feature.properties.id) || feature.properties.id < 0) {
          items.push({
            id: feature.properties.id,
            name: feature.properties.name
          })
        }
      }
      return items
    },

    spotOwner: state => id => {
      for (let feature of state.spots) {
        if (feature.properties.id == id) {
          return feature.properties.owner
        }
      }
    },

    filterInput: state => state.filter
  }
})

// To cancel request if function is repeatedly calling within time span
let syncTimeout = null

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state));

  // sync 
  if (state.settings.sync) {
    switch (mutation.type) {
      case 'updateMapstate': {
        clearTimeout(syncTimeout)
        syncTimeout = setTimeout(() => {
          const sync = new Sync(state.user.id)
          sync.map(state.map)
        }, 5000)

      }

    }
  }
});

export default store