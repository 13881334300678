<template>
  <nav
    class="navbar is-primary is-transparent"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <strong class="is-size-4">{{ brandName }}</strong>
      </router-link>
      <router-link
        class="navbar-item is-tab"
        v-for="tab in tabs"
        :key="tab.id"
        :to="tab.to"
        @mouseup="closeContent"
        >{{ tab.name }}
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': showBurgerContent }"
        @click="toggleBurgerContent"
        aria-label="menu"
        aria-expanded="false"
        data-target="menuItems"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div
      id="menuItems"
      class="navbar-menu"
      :class="{ 'is-active': showBurgerContent }"
    >
      <div class="navbar-end">
        <figure class="image is-48x48">
          <img class="is-rounded" :src="$store.getters.userbasic.image" />
        </figure>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> {{ $store.getters.userbasic.nickname }} </a>
          <div
            class="navbar-dropdown is-right"
            :style="{ display: showDropDown }"
          >
            <router-link
              class="navbar-item"
              v-for="item in dropdown"
              :key="item.id"
              :to="item.to"
              @mouseup="closeContent"
              >{{ item.name }}
            </router-link>
            <hr class="navbar-divider" />
            <div class="navbar-item">
              <button
                class="button is-dark"
                :class="{ 'is-loading': isLoading }"
                @click="logout"
                :disabled="!$store.getters.authState"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationBar",

  data() {
    return {
      brandName: "Bulli Spots",
      tabs: [
        { name: "Map", to: "/map", active: true },
        { name: "List", to: "/list", active: false },
        { name: "Ideas‽", to: "/contact", active: false },
      ],
      dropdown: [
        { name: "Profile", to: "/profile" },
        { name: "Your Spots", to: "/manage" },
        { name: "Settings", to: "/settings" },
        { name: "About Us", to: "/about" },
      ],
      showDropDown: "",
      isLoading: false,
    };
  },

  computed: {
    showBurgerContent() {
      return this.$store.getters.menuOpen;
    },
  },

  methods: {
    logout() {
      this.isLoading = true;

      fetch(`${process.env.VUE_APP_API_URL}/auth/logout`, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((response) => {
          if (response.status == 200) {
            this.$store.dispatch("deleteUser");
            this.$store.commit("authChange", false);
            Promise.resolve(this.$router.push('/logout'));
          } else {
            this.isLoading = false;
            return Promise.reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleBurgerContent() {
      this.$store.commit("menuChange", !this.showBurgerContent);
    },

    closeContent() {
      // In Desktop dropdown menu stay open after click any content in it. so it need to be manually closed.
      this.showDropDown = "none";
      this.$store.commit("menuChange", false);
      // Set dropdown menu state back to the original state. That state is hidden but visible in hover.
      // If display would stay 'none' the dropdown will be never seen again.
      window.setTimeout(() => {
        this.showDropDown = "";
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: hsl(33, 99%, 62%);
    }
  }
}
</style>

