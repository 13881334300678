import store from '@/store'

export const authGuard = (to, from, next) => {
  const hasPermission = store.getters.authState;
  // If the user is authenticated, continue with the route
  if (hasPermission) {
    next()
    // Otherwise, go to log in page
  } else {
    console.warn('Not authorized. Please log in.')

    // Enter side by root domain do not add any query
    if (to.fullPath == '/') {
      next({
        name: 'login',
      })
    } else {
      next({
        name: 'login',
        query: { redirectFrom: to.fullPath }
      })
    }
  }
} 