<template>
  <div class="columns is-gapless">
    <div class="column">
      <div :style="{ height: heightMap + 'px' }">
        <l-map
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          :minZoom="3"
          :maxZoom="18"
          @update:center="centerUpdated"
          @update:zoom="zoomUpdated"
          @contextmenu="addSpot"
          @click="closeDialog"
          @ready="onReady"
          @locationfound="onLocationFound"
          @locationerror="onLocationError"
          style="z-index: 0"
        >
          <l-control-scale
            :maxWidth="250"
            position="bottomleft"
            :imperial="false"
          ></l-control-scale>
          <l-control position="bottomright">
            <img
              src="@/assets/mapbox_sat.jpg"
              alt="Satellite"
              class="control"
              @click="mapbox.id = 'mapbox/satellite-v9'"
            />
            <img
              src="@/assets/mapbox_street.jpg"
              alt="Streets"
              class="control"
              @click="mapbox.id = 'mapbox/streets-v11'"
            />
          </l-control>
          <l-control position="bottomright">
            <img
              src="@/assets/locateme_bus_icon.svg"
              alt="Locate me"
              class="control"
              @click="locateMe"
            />
          </l-control>
          <l-tile-layer
            :url="url"
            :attribution="mapbox.attribution"
          ></l-tile-layer>
          <!-- marker for spot -->
          <l-marker
            v-for="spot in spots"
            :key="spot.properties.id"
            :latLng="spot.geometry.coordinates"
            @click="showInfo(spot.properties.id)"
          >
            <l-icon
              v-if="spot.properties.favorite"
              :icon-url="require('../assets/marker-icon-orange.png')"
            ></l-icon>
            <l-icon
              v-else-if="spot.properties.id < 0"
              :icon-url="require('../assets/marker-icon-red.png')"
            ></l-icon>
          </l-marker>
          <!-- marker for new spot -->
          <l-marker
            v-if="dialog.active == 'newSpot'"
            v-model:latLng="newSpot.latLng"
            draggable
          >
            <l-icon
              :icon-url="require('../assets/marker-icon-green.png')"
            ></l-icon>
            <l-tooltip
              :options="{
                direction: 'top',
                offset: [0, -40],
              }"
            >
              Here should be
              <br />a new spot&#8253;
            </l-tooltip>
          </l-marker>
          <!-- marker for location -->
          <l-marker v-if="location.active" :latLng="location.latLng">
            <l-icon
              :icon-url="require('../assets/locate_bus_icon.svg')"
              :icon-size="location.size"
            />
          </l-marker>
        </l-map>
      </div>
    </div>
    <div class="column" :class="dialog.size" v-if="dialog.active">
      <SpotDetails
        v-if="dialog.active == 'detailsSpot'"
        :id="detailsSpot.id"
        :store="$store"
      ></SpotDetails>
      <FormNewSpot
        v-if="dialog.active == 'newSpot'"
        :latLng="newSpot.latLng"
        :store="$store"
      ></FormNewSpot>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControl,
  LControlScale,
  LIcon,
  LTooltip,
} from "@vue-leaflet/vue-leaflet";
import FormNewSpot from "@/components/FormNewSpot.vue";
import SpotDetails from "@/components/SpotDetails.vue";

export default {
  name: "MapPage",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LControlScale,
    LIcon,
    LTooltip,
    FormNewSpot,
    SpotDetails,
  },

  props: {
    // If exist a spot should be open by default
    spotId: {
      Type: Number,
      default: null,
    },
  },

  data() {
    return {
      map: {},
      heightDialog: 0,
      heightFull: 800,
      mapOptions: { zoomControl: false },
      mapbox: {
        url: "https://api.mapbox.com/styles/v1",
        type: "tiles",
        id: "mapbox/streets-v11",
        accessToken:
          "pk.eyJ1IjoiY29paWxlbGEiLCJhIjoiY2lrN3pmNDFzMDBvZXR4a3dhaWFzOTY2eCJ9.A7MckkPieMscrBycevDfCg",
        attribution:
          'Map Data &copy;<a href="https://www.openstreetmap.org/">OSM</a> contributors, Imagery &copy;<a href="https://www.mapbox.com/">Mapbox</a>',
      },
      spots: [],
      newSpot: {
        latLng: {},
      },
      detailsSpot: {
        id: null,
      },
      dialog: {
        active: "",
        size: "",
      },
      location: {
        active: false,
        latLng: {},
        size: [30, 30],
      },
    };
  },

  computed: {
    settings() {
      return this.$store.getters.mapstate;
    },

    center: {
      get() {
        return [this.settings.lat, this.settings.lng];
      },
      set(val) {
        this.$store.dispatch("updateMapstate", { center: val });
      },
    },

    zoom: {
      get() {
        return this.settings.zoom;
      },
      set(val) {
        this.$store.dispatch("updateMapstate", { zoom: val });
      },
    },

    menu() {
      return this.$store.getters.menuOpen;
    },

    getSpots() {
      let spots = this.$store.getters.mapItems;
      let filter = this.$store.getters.filterInput;
      return spots.filter((spot) => {
        return spot.properties.name.toLowerCase().includes(filter);
      });
    },

    url() {
      return `${this.mapbox.url}/${this.mapbox.id}/${this.mapbox.type}/{z}/{x}/{y}?access_token=${this.mapbox.accessToken}`;
    },

    heightMap() {
      return this.heightFull - this.heightDialog;
    },

    dialogHeight() {
      // in case of dialog from right side
      if (
        document.documentElement.offsetWidth >= 768 ||
        window.matchMedia("(orientation: landscape)").matches
      ) {
        return this.heightFull;
      } else {
        return 500;
      }
    },
  },

  watch: {
    getSpots: {
      handler: function (newSpots) {
        let currentLength = this.spots.length;
        // delete whole array and replace with new content
        this.spots.splice(0, currentLength, ...newSpots);
      },
      immediate: true,
    },

    menu: function (newVal) {
      if (!newVal) {
        this.handleResize();
      }
    },
  },

  methods: {
    // maximum height of content which is view hight minus navigation bar
    // DOM objects can not be made reactive. If height change you should call this function.
    getHeightFull() {
      this.heightFull =
        document.documentElement.clientHeight -
        document.querySelector("#app > #nav").offsetHeight;
    },

    handleResize() {
      return new Promise((resolve) => {
        // make sure that DOM is be updated
        setTimeout(() => {
          this.getHeightFull();
          // make sure height value are correct after DOM refresh
          setTimeout(() => {
            this.map.invalidateSize({ pan: false, debounceMoveend: true });
          }, 200);
          resolve();
        }, 100);
      });
    },

    /**************  dialog context  **************/
    openDialog(reqContent, mapCenter, size) {
      this.$router.push(`/spot/${this.detailsSpot.id}`);
      // size value must be a Bulma column size
      this.dialog.size = size;
      this.dialog.active = reqContent;

      if (document.documentElement.offsetWidth <= 768) {
        // media point mobile
        // convert Bulma tags to numbers
        const sizeFactor = () => {
          switch (size) {
            case "is-one-third":
              return 0.33;
            case "is-two-thirds":
              return 0.66;
            default:
              return 1;
          }
        };
        this.heightDialog = this.heightFull * sizeFactor();
      }

      // define new map area and move map that marker is centered
      // timeout for waiting until dom rendering in done
      setTimeout(() => {
        this.map.invalidateSize({ pan: false, debounceMoveend: true });
        this.center = mapCenter;
      }, 100);
    },

    closeDialog(event) {
      // Do not close anything by moving a marker
      if (event.srcElement !== undefined && !event.srcElement.draggable) {
        this.dialog.active = "";
        // Necessary to calculate the height of the map correctly after dialog closed
        this.heightDialog = 0;

        this.$router.push("/map");
        // force resize, because it isn't work by default
        this.handleResize();
      }
    },

    addSpot(target) {
      // Close menu also when open context menu. Global setting is close by click anywhere
      this.$store.commit("menuChange", false);

      // the first contextmenu event is the default browser contextmenu without any leaflet stuff like latlng
      if (target.latlng !== undefined) {
        this.newSpot.latLng = target.latlng;

        this.detailsSpot.id = this.$store.getters.newSpotId;
        this.openDialog("newSpot", this.newSpot.latLng, "is-two-thirds");
      }
    },

    showInfo(spotId) {
      //this.$parent.$emit("closeNavBar");
      const spot = this.spots.find((spot) => spot.properties.id == spotId);
      const waitToLoad = spot !== undefined ? 0 : 1000;

      window.setTimeout(() => {
        const spot = this.spots.find((spot) => spot.properties.id == spotId);

        const latlng = {
          lat: spot.geometry.coordinates[0],
          lng: spot.geometry.coordinates[1],
        };

        this.detailsSpot.id = spotId;
        this.openDialog("detailsSpot", latlng, "is-one-third");
      }, waitToLoad);
    },

    /**************  leaflet context  **************/
    onReady(mapObject) {
      this.map = mapObject;
      mapObject.locate();
    },

    zoomUpdated(zoom) {
      this.zoom = zoom;
    },

    centerUpdated(center) {
      this.center = center;
    },

    locateMe() {
      this.map.locate({
        setView: true,
        maxZoom: 16,
      });
    },

    onLocationFound(location) {
      this.location.active = true;
      this.location.latLng = location.latlng;
    },
    onLocationError(locationError) {
      console.warn(locationError);
    },
  },

  created() {
    this.getHeightFull();
    window.addEventListener("orientationchange", this.handleResize);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("closeDialog", this.closeDialog);

    this.getSpots;
  },

  mounted() {
    if (this.spotId !== null) {
      this.showInfo(this.spotId);
    }
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.redirectFrom) {
      next((vm) => {
        vm.warningMsg =
          "You know where to find good stuff 👍. However please log in first.";
      });
    } else {
      next();
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query.redirectFrom) {
      this.warningMsg = "C'mon. Please log in first!";
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.dialog {
  overflow-y: scroll;
}

.control {
  width: 52px;
  border: 2px solid #aaa;
  border-radius: 15px;
  cursor: pointer;
}

.font_icon {
  font-size: large;
  margin-left: 0.5em;
}
</style>

<style>
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 26px;
}
</style>