const sendRequest = async (id, data) => {
  const answer = await fetch(`${process.env.VUE_APP_API_URL}/user/${id}`, {
    method: "PUT",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 200) {
          return {
            status: response.status,
            data: response.json()
          }
      }
      else {
        return {
          status: response.status
        }
      }
    })
    .catch((error) => {
      console.error("Error during update sync status: " + error);
    })

    // if data in answer resolve the promise. It is not possible to resolve the json parsing
    // because nested await statments are not allowed
    if ('data' in answer) {
      answer.data = await answer.data
    }
    return answer
}

export class Sync {
  constructor(id) {
    this.id = id
  }

  async enable(map, fav, change) {
    // map, fav, change = {}, [], ""
    const syncJson = {
      settings: {
        sync: true
      },
      sync: {
        mapstate: map,
        favorites: fav,
        changelog: change,
      }
    }
    const res = await sendRequest(this.id, syncJson)
    return res 
  }

  async map(map) {
    // map = {}
    const syncJson = {
      settings: {
        sync: true
      },
      sync: {
        mapstate: map,
      }
    }

    return await sendRequest(this.id, syncJson)
  }

  async favorite(fav) {
    // fav = []
    const syncJson = {
      settings: {
        sync: true
      },
      sync: {
        favorites: fav,
      }
    }

    return await sendRequest(this.id, syncJson)
  }

  async changelog(log) {
    // change = ""
    const syncJson = {
      settings: {
        sync: true
      },
      sync: {
        changelog: log,
      }
    }

    return await sendRequest(this.id, syncJson)
  }

  async disable() {
    const syncJson = {
      settings: {
        sync: false
      }
    }

    return await sendRequest(this.id, syncJson)
  }
}