<template>
  <div id="nav">
    <NavigationBar v-if="$store.getters.authState" />
  </div>
  <router-view @click="closeMenu()"/>
</template>

<script>
import NavigationBar from "./components/Navigation.vue";

export default {
  name: "App",

  components: {
    NavigationBar,
  },

  methods: {
    closeMenu() {
      this.$store.commit('menuChange', false)
    },
  },

  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
