<template>
  <div class="login">
    <h1 class="title is-1 pb-6 has-text-centered">Your spot is your life</h1>
    <div class="columns" v-if="warningMsg">
      <div class="column is-half is-offset-one-quarter">
        <div class="notification is-warning is-light">{{ warningMsg }}</div>
      </div>
    </div>
    <div class="section">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <FormLogin></FormLogin>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-8">
            <WisdomCard class="wisdom" author="kneckox">"Live is good"</WisdomCard>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <WisdomCard class="wisdom" author="Radio Bollerwagen"
              >"Wer sein Bollerwagen liebt, der zieht"</WisdomCard
            >
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-4">
            <WisdomCard class="wisdom" author="kneckox"
              >"Endlich ergibt das alles einen Sinn!"</WisdomCard
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormLogin from "@/components/Credentials.vue";
import WisdomCard from "@/components/Wisdom.vue";

export default {
  name: "LoginPage",
  components: {
    FormLogin,
    WisdomCard,
  },
  data() {
    return {
      warningMsg: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.redirectFrom) {
      next((vm) => {
        vm.warningMsg =
          "You know where to find good stuff 👍. However please log in first.";
      });
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.redirectFrom) {
      this.warningMsg = "C'mon. Please log in first!";
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-shadow: 0px 0px 7px #440e0688;
}

.login {
  position: absolute;
  top: 0;
  @media (min-height: 1000px) {
    bottom: 0;
  }
  width: 100%;
  padding-top: 10%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.65),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.25)
    ),
    url("../assets/spot149b.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.wisdom {
  background-color: transparent;
}
</style>
