<template>
  <div></div>
</template>

<script>
import { toast as bulmaToast } from "bulma-toast";

export default {
  name: "NotificationItem",

  props: {
    text: String,
    type: String,
    animation: Boolean,
  },

  watch: {
    animation: {
      handler: function (newVal) {
        if (newVal) bulmaToast({
          message: this.text,
          type: this.type,
          position: "top-center",
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      },
    },
  },
};
</script>