<template>
  <Notification
    :text="notification.text"
    :type="notification.type"
    :animation="notification.active"
  />
  <div class="card mb-1">
    <header class="card-header">
      <p class="card-header-title is-size-4">
        {{ spot.properties.name }}
      </p>
    </header>
    <header class="card-header">
      <div class="columns is-mobile card-header-title">
        <div class="column is-one-fifth has-text-centered">
          <a class="icon has-text-dark" :href="spot.properties.route">
            <i class="fas fa-directions"></i>
          </a>
        </div>
        <div
          v-if="webShareApiSupported"
          class="column is-one-fifth has-text-centered"
        >
          <a class="icon has-text-dark" @click="shareViaWebShare">
            <i class="fas fa-share-alt"></i>
          </a>
        </div>
        <div v-else class="column is-one-fifth has-text-centered">
          <a class="icon has-text-dark" @click="shareViaClipboard">
            <i class="fas fa-share-alt"></i>
          </a>
        </div>
      </div>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-mobile" v-if="spot.properties.fee !== 'unknown'">
          <div class="column">Costs:</div>
          <div class="column" v-if="spot.properties.fee">
            <span v-if="Number.isFinite(spot.properties.costs)">
              {{ spot.properties.costs.toFixed(2)
              }}<span class="icon"><i class="fas fa-euro-sign"></i></span>
            </span>
            <span v-else class="icon">
              <i class="fas fa-coins"></i>
            </span>
          </div>
          <div class="column" v-else>
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div
          class="columns is-mobile"
          v-if="spot.properties.parking !== 'unknown'"
        >
          <div class="column">Offical parking:</div>
          <div class="column" v-if="spot.properties.parking">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div class="column" v-else>
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div
          class="columns is-mobile"
          v-if="spot.properties.night !== 'unknown'"
        >
          <div class="column">Overnight parking:</div>
          <div class="column" v-if="spot.properties.night">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div class="column" v-else>
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div
          class="columns is-mobile"
          v-if="spot.properties.heightlimited !== 'unknown'"
        >
          <div class="column">Height limited:</div>
          <div class="column" v-if="spot.properties.heightlimited">
            <span v-if="Number.isFinite(spot.properties.heightlimit)">
              {{ spot.properties.heightlimit.toFixed(2) }} m
            </span>
            <div v-else>
              <span class="icon">
                <i class="fas fa-check"></i>
              </span>
              <span class="icon has-text-danger">
                <i class="fas fa-arrows-alt-v"></i>
              </span>
            </div>
          </div>
          <div class="column" v-else>
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div
          class="columns is-mobile"
          v-if="spot.properties.toilette !== 'unknown'"
        >
          <div class="column">Toilette:</div>
          <div class="column" v-if="spot.properties.toilette">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div class="column" v-else>
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div
          class="columns is-mobile"
          v-if="spot.properties.seaview !== 'unknown'"
        >
          <div class="column">Sea view:</div>
          <div class="column" v-if="spot.properties.seaview">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div class="column" v-else>
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-1">
    <div class="card-content">
      <div
        v-if="Array.isArray(spot.properties.pics)"
        class="columns is-multiline is-centered is-mobile"
      >
        <div
          v-for="thumb in imageList"
          :key="thumb.id"
          class="column is-narrow"
        >
          <div class="card-image">
            <figure class="image thumb">
              <img :src="thumb.url" @click.stop="showLightbox(thumb.name)" />
            </figure>
          </div>
        </div>
        <Lightbox
          :images="imageListLarge"
          :currentImageName="currentImageName"
          @on-lightbox-close="onLightboxClose"
          @click.stop=""
        />
      </div>
      <div v-else class="columns is-mobile">
        <div
          class="
            column
            is-centered
            is-half-desktop
            is-two-thirds-tablet
            is-offset-one-quarter-desktop
            is-offset-2-tablet
          "
        >
          <div class="card-image">
            <figure class="image">
              <img
                src="@/assets/defaultSpot.svg"
                alt="No picture for that spot"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="spot.properties.notes" class="card mb-1">
    <div class="card-content">
      <p class="title is-5">More information</p>
      <p>{{ spot.properties.notes }}</p>
    </div>
  </div>
  <div class="card mb-1">
    <div class="card-content">
      <h2 class="title is-5">Discovered by</h2>
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img :src="owner.image" alt="Profile image" />
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-5">{{ owner.name }}</p>
          <p class="subtitle is-6">@{{ owner.nickname }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lightbox from "vue-my-photos/src/lightbox.vue";
import Notification from "@/components/Notification.vue";

export default {
  name: "SpotDetails",

  components: {
    Lightbox,
    Notification,
  },

  props: {
    id: Number,
    store: Object,
  },

  data() {
    return {
      currentImageName: "",
      lightboxImageSize: "?size=large",
      owner: {},
      notification: {
        active: false,
        type: "",
        text: "",
      },
    };
  },

  computed: {
    spot() {
      return this.store.getters.detailsSpot(this.id);
    },
    imageList() {
      if (Array.isArray(this.spot.properties.pics)) {
        let imageArray = [];
        for (let pic of this.spot.properties.pics) {
          imageArray.push({
            name: pic,
            url: pic + "?size=thumb",
          });
        }
        return imageArray;
      } else {
        return [];
      }
    },
    imageListLarge() {
      if (Array.isArray(this.spot.properties.pics)) {
        let imageArray = [];
        for (let pic of this.spot.properties.pics) {
          imageArray.push({
            name: pic + this.lightboxImageSize,
          });
        }
        return imageArray;
      } else {
        return [];
      }
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },

  watch: {
    spot: {
      handler: function (nextSpot) {
        this.getOwnerData(nextSpot);
      },
      immediate: true,
    },
  },

  methods: {
    showLightbox(imageName) {
      this.currentImageName = imageName + this.lightboxImageSize;
    },

    onLightboxClose(imageName) {
      this.currentImageName = imageName;
    },

    showAlert(settings) {
      return new Promise((resolve) => {
        this.notification = settings;

        // Set back to default because notification is only triggered if switch to 'true'
        setTimeout(() => {
          this.notification.active = false;
          resolve();
        }, 100);
      });
    },

    getOwnerData(spot) {
      fetch(`${process.env.VUE_APP_API_URL}/user/${spot.properties.owner}`, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((json) => {
              this.owner = json;
            });
          }
        })
        .catch((error) => {
          console.warn("Error get owner profile: " + error);
        });
    },

    shareViaWebShare() {
      const url =
        window.location.origin + process.env.VUE_APP_PUBLIC_PATH + this.spot.properties.share;
        
      navigator.share({
        title: "Awesome spot",
        text: "Look at this pretty spot shared with you.",
        url: url,
      });
    },

    shareViaClipboard() {
      const url =
        window.location.origin + process.env.VUE_APP_PUBLIC_PATH + this.spot.properties.share;

      navigator.clipboard.writeText(url);

      this.showAlert({
        active: true,
        type: "is-success is-light",
        text: "Link copied to clipboard &#128203;",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
figure.thumb {
  width: 240px;
  height: 180px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
