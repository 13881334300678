<template>
  <div class="box transparency">
    <div class="notification is-danger is-light" v-if="isIncorrect">
      <strong>Incorrect login data</strong>
      <p>please try again</p>
    </div>
    <div class="notification is-danger" v-if="fail">
      <strong>Something went wrong. Try again later.</strong>
      <p>We will rush that bug! &#128030;</p>
    </div>
    <div class="notification is-danger" v-if="down">
      <strong>Service is currently unavailable. Please try again later.</strong>
      <p>We feel with you 😞</p>
    </div>
    <div class="notification is-danger" v-if="bad">
      <strong>Wrong request in any kind.</strong>
      <p>We have no idea how you did that??? 🤔</p>
    </div>
    <form>
      <div class="field">
        <label class="label">Username</label>
        <div class="control has-icons-left">
          <input
            autocomplete="username"
            placeholder="BulliMan or any other cool name"
            class="input"
            @change="checkValid"
            @keyup="checkValid"
            v-model="input.name"
            required
          />
          <span class="icon is-small is-left">
            <i class="fas fa-user"></i>
          </span>
        </div>
        <small id="emailHelp" class="form-text text-muted"
          >We'll never share your email with anyone else.</small
        >
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left has-icons-right">
          <input
            ref="inputPw"
            autocomplete="current-password"
            placeholder="Some stupid password"
            class="input"
            :type="pwLook"
            @change="checkValid"
            @keyup="checkValid"
            v-model="input.password"
            required
          />
          <span class="icon is-small is-left">
            <i class="fas fa-key"></i>
          </span>
          <span
            class="icon is-small is-right to-pointer"
            style="pointer-events: all"
            :class="{ 'is-hidden': !pwFocused }"
            @click="switchPwType"
          >
            <i v-show="pwLook == 'password'" class="fas fa-eye"></i>
            <i v-show="pwLook != 'password'" class="fas fa-eye-slash"></i>
          </span>
        </div>
      </div>
      <button
        @click.prevent="submit"
        class="button is-primary is-fullwidth mt-5"
        :class="{ 'is-loading': isLoading }"
        type="submit"
        :disabled="validSubmit"
      >
        <strong>Sign in</strong>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "FormLogin",

  data() {
    return {
      input: {
        name: "",
        password: "",
      },
      validSubmit: true,
      pwFocused: false,
      pwLook: "password",
      isLoading: false,
      isIncorrect: false,
      fail: false,
      down: false,
      bad: false,
    };
  },

  methods: {
    submit() {
      this.isIncorrect = false;
      this.isLoading = true;
      this.validSubmit = "disabled";

      let formData = [];
      for (let property in this.input) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(this.input[property]);
        formData.push(encodedKey + "=" + encodedValue);
      }
      formData = formData.join("&");

      fetch(`${process.env.VUE_APP_API_URL}/auth/login`, {
        method: "POST",
        credentials: "same-origin",
        body: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((json) => {
              this.$store.commit("createUser", json);
              this.loadUser(json.id);
            });
            this.$store.dispatch("authChange", true);

            // redirectFrom parameter will contain url the user enter while not logged in.
            // In this case the user will get the requested content after login
            const route = this.$route.query.redirectFrom
              ? this.$route.query.redirectFrom
              : "/";
            Promise.resolve(this.$router.push(route));
          } else if (response.status == 403) {
            return Promise.resolve(this.wrongLogin());
          } else if (response.status == 503) {
            return Promise.resolve(this.serviceDown());
          } else if (response.status <= 499 && response.status >= 400) {
            return Promise.resolve(this.requestBad());
          } else return Promise.reject();
        })
        .catch((error) => {
          console.log(error);
          this.fail = true;
          this.isLoading = false;
        });
    },

    async loadUser(userId) {
      fetch(`${process.env.VUE_APP_API_URL}/user/${userId}`, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              response.json().then((json) => {
                this.$store.dispatch("updateUser", json);
              });
              break;

            case 401:
              // logged out in app
              this.$store.commit("authChange", false);
              // place to authentification / login again
              this.$router.push("/");
          }
        })
        .then((response) => {
          switch (response.status) {
            case 200:
              response.json().then((json) => {
                this.$store.dispatch("updateUser", json);
              });
              break;

            case 401:
              // logged out in app
              this.$store.commit("authChange", false);
              // place to authentification / login again
              this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log("Error during update profile: " + error);
        });
    },

    wrongLogin() {
      this.isLoading = false;
      this.isIncorrect = true;
    },

    serviceDown() {
      this.isLoading = false;
      this.down = true;
    },
    requestBad() {
      this.isLoading = false;
      this.bad = true;
    },

    checkValid() {
      if (this.input.name && this.input.password) {
        this.validSubmit = null;
        this.pwFocused = true;
      } else if (this.input.password) {
        this.pwFocused = true;
      } else {
        this.validSubmit = "disabled";
        this.pwFocused = false;
      }
    },

    switchPwType() {
      this.pwLook = this.pwLook == "password" ? "text" : "password";
      this.$refs.inputPw.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  font-family: inherit;
}

.transparency {
  background-color: transparent;
  backdrop-filter: blur(5px);
}

.to-pointer {
  cursor: pointer;
}
</style>