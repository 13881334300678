<template>
  <Notification
    v-show="notification.active"
    :text="notification.text"
    :type="notification.type"
    :animation="notification.active"
  />
  <div class="create-window">
    <div v-show="onCreate" class="card">
      <header id="header-one" class="card-header">
        <p class="card-header-title is-size-4">
          A new spot named "<span class="is-italic">{{
            spot.properties.name || "..."
          }}</span
          >"
        </p>
      </header>
      <header class="card-header">
        <p class="card-header-title">
          <button
            class="button is-success mr-2"
            :class="{ 'is-loading': isLoadingNew }"
            @click="createSpot()"
          >
            <span class="icon has-text-dark">
              <i class="fas fa-edit"></i>
            </span>
            <span>Save</span>
          </button>
          <button class="button mr-5" @click="cancel()">
            <span>Cancel</span>
          </button>
        </p>
      </header>
      <div class="card-content">
        <form>
          <fieldset :disabled="isLoadingNew">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Name: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <input
                    class="input"
                    type="text"
                    minlength="1"
                    maxlength="256"
                    v-model="spot.properties.name"
                  />
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Fee: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="fee"
                        value="true"
                        v-model="spot.properties.fee"
                      />
                      Yes
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="fee"
                        value="false"
                        v-model="spot.properties.fee"
                      />
                      No
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="fee"
                        value="unknown"
                        v-model="spot.properties.fee"
                      />
                      Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="dependsOn.fee === 'true'" class="field is-horizontal">
              <div class="field-label is-normal"></div>
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control">
                    <input
                      type="number"
                      step="0.01"
                      class="input"
                      v-model.number="spot.properties.costs"
                      placeholder="e.g. 3,50"
                    />
                  </div>
                  <div class="control">
                    <a class="button is-static">
                      <span class="icon"><i class="fas fa-euro-sign"></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left">Offical parking: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="parking"
                        value="true"
                        v-model="spot.properties.parking"
                      />
                      Yes
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="parking"
                        value="false"
                        v-model="spot.properties.parking"
                      />
                      No
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="parking"
                        value="unknown"
                        v-model="spot.properties.parking"
                      />
                      Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Overnight parking: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="night"
                        value="true"
                        v-model="spot.properties.night"
                      />
                      Yes
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="night"
                        value="false"
                        v-model="spot.properties.night"
                      />
                      No
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="night"
                        value="unknown"
                        v-model="spot.properties.night"
                      />
                      Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Toilette: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="toilette"
                        value="true"
                        v-model="spot.properties.toilette"
                      />
                      Yes
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="toilette"
                        value="false"
                        v-model="spot.properties.toilette"
                      />
                      No
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="toilette"
                        value="unknown"
                        v-model="spot.properties.toilette"
                      />
                      Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Seaview: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="seaview"
                        value="true"
                        v-model="spot.properties.seaview"
                      />
                      Yes
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="seaview"
                        value="false"
                        v-model="spot.properties.seaview"
                      />
                      No
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="seaview"
                        value="unknown"
                        v-model="spot.properties.seaview"
                      />
                      Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Height limit: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="heightlimited"
                        value="true"
                        v-model="spot.properties.heightlimited"
                      />
                      Yes
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="heightlimited"
                        value="false"
                        v-model="spot.properties.heightlimited"
                      />
                      No
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="heightlimited"
                        value="unknown"
                        v-model="spot.properties.heightlimited"
                      />
                      Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="dependsOn.heightlimited" class="field is-horizontal">
              <div class="field-label is-normal"></div>
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control">
                    <input
                      type="number"
                      step="0.1"
                      class="input"
                      v-model.number="spot.properties.heightlimit"
                      placeholder="e.g. 2,1 (in meter)"
                    />
                  </div>
                  <div class="control">
                    <a class="button is-static"> m </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left"> Notes: </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <textarea
                      class="textarea"
                      placeholder="Space for any freaky infos or crazy remarks..."
                      v-model="spot.properties.notes"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label has-text-left"> Picture: </label>
            </div>
            <div class="file">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  accept="image/*"
                  name="new-pic"
                  @change="onFileChange"
                />
                <div v-if="image.name == ''" class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">We want your photos!</span>
                </div>
                <div v-else class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-sync"></i>
                  </span>
                  <span class="file-label">Change photo</span>
                </div>
              </label>
              <span
                v-show="image.name"
                class="button is-danger is-light ml-3"
                @click="removeImage"
              >
                Remove photo
              </span>
            </div>
            <div v-if="image.preview">
              <div class="columns mt-3">
                <div class="column is-half-desktop is-two-thirds-tablet">
                  <div class="card-image">
                    <progress
                      v-if="isLoadingImage"
                      class="progress is-primary is-medium px-5 on-image"
                    />
                    <figure class="image">
                      <img :src="image.preview" alt="New Picture to be added" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";

export default {
  name: "NewSpot",

  components: {
    Notification,
  },

  props: {
    store: Object,
    latLng: Object,
  },

  data() {
    return {
      onCreate: true,
      isLoadingNew: false,
      isLoadingImage: false,
      spot: {
        properties: {},
      },
      dependsOn: {
        fee: false,
        heightlimited: false,
      },
      notification: {
        active: false,
        type: "",
        text: "",
      },
      currentImageName: "",
      image: {
        name: "",
        data: null,
        preview: "",
      },
    };
  },

  computed: {
    newSpotId() {
      return this.$store.getters.newSpotId;
    },
  },

  watch: {
    "spot.properties": {
      handler: function (newVal) {
        if (newVal.fee === true || newVal.fee === "true") {
          this.dependsOn.fee = "true";
        } else {
          this.dependsOn.fee = "";
        }
        if (newVal.heightlimited === true || newVal.heightlimited === "true") {
          this.dependsOn.heightlimited = "true";
        } else {
          this.dependsOn.heightlimited = "";
        }
      },
      deep: true,
    },
  },

  methods: {
    createUploadJson() {
      // json template
      const json = {
        type: "Feature",
        geometry: {},
        properties: {},
      };

      // -- geometry handling --
      json.geometry = {
        type: "Point",
        coordinates: [this.latLng.lng, this.latLng.lat],
      };

      // -- properties handling --
      // boolean props
      const booleanProps = [
        "fee",
        "night",
        "parking",
        "toilette",
        "seaview",
        "heightlimited",
      ];

      // filter out 'unknown' props
      for (const prop of booleanProps) {
        const test = this.spot.properties[prop];
        switch (test) {
          case "true":
            json.properties[prop] = true;
            break;
          case "false":
            json.properties[prop] = false;
        }
      }

      // conditional props
      if (json.properties.fee) {
        if (this.spot.properties.costs > 0)
          json.properties.costs = this.spot.properties.costs;
      }
      if (json.properties.heightlimited) {
        if (this.spot.properties.heightlimit > 0)
          json.properties.heightlimit = this.spot.properties.heightlimit;
      }

      // static props
      json.properties.id = this.newSpotId;
      json.properties.name = this.spot.properties.name;
      if (this.spot.properties.notes)
        json.properties.notes = this.spot.properties.notes;

      return json;
    },

    createSpot() {
      this.isLoadingNew = true;
      const data = this.createUploadJson();
      // store local first
      // submit a cloned json to the data store to avoid changes on the origin object during the
      // following server request.
      const dataClone = JSON.parse(JSON.stringify(data));
      this.store.dispatch("createSpot", dataClone);

      if (!window.navigator.onLine) {
        this.onCreate = false;
        this.isLoadingNew = false;
        this.showAlert({
          active: true,
          type: "is-warning is-light",
          text: "You are offline. Your new spot is saved locally and will sync later.",
        }).then(() => {
          // Close dialog after the notification inside the dialog ends.
          this.close();
        });
      } else {
        fetch(`${process.env.VUE_APP_API_URL}/spot`, {
          method: "POST",
          credentials: "same-origin",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            this.onCreate = false;
            this.isLoadingNew = false;
            switch (response.status) {
              case 201:
                response.json().then((json) => {
                  // A few lines before the spot is created locally. At this point the getter newSpotId()
                  // will return the next free id, which is in this case -1 to the origin id
                  this.store.dispatch("updateSpot", {
                    data: json,
                    new: this.newSpotId + 1,
                  });

                  // Add new spot to owner list (without new request)
                  const owner_spots_old = this.store.getters.ownSpots;
                  const owner_spots_new = [json.properties.id];
                  for (const spot of owner_spots_old) {
                    owner_spots_new.push(spot.id);
                  }
                  this.store.dispatch("updateUser", { owner: owner_spots_new });

                  let text = "";
                  if (this.image.data) {
                    text =
                      "Awesome! Thank you for this Place. Your picture is still uploading!";
                  } else {
                    text = "Thanks for this awesome spot. &#128077;";
                  }
                  this.showAlert({
                    active: true,
                    type: "is-success is-light",
                    text: text,
                  }).then(() => {
                    // Close dialog after the notification inside the dialog ends.
                    this.close();
                  });

                  // Handle upload image after creation because the ID of the spot is necessary.
                  // The upload is done on another method
                  if (this.image.data) this.saveImage(json.properties.id);
                });
                break;

              case 401:
                // logged out in app
                this.$store.commit("authChange", false);
                // place to authentification / login again
                this.$router.push("/");
                break;

              default:
                this.isLoadingNew = false;
                this.showAlert({
                  active: true,
                  type: "is-danger",
                  text: "<p>Your new spot only is saved locally and will sync later because <strong>something went wrong.</strong></p><p>We will rush that bug! &#128030;</p>",
                }).then(() => {
                  // Close dialog after the notification inside the dialog ends.
                  this.close();
                });
            }
          })
          .catch((error) => {
            console.log("Error during the creation of the spot: " + error);
          });
      }
    },

    saveImage(spotId) {
      this.isLoadingImage = true;
      const formData = new FormData();
      formData.append("image", this.image.data);

      fetch(`${process.env.VUE_APP_API_URL}/spot/${spotId}/image`, {
        method: "POST",
        credentials: "same-origin",
        body: formData,
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              response.json().then((json) => {
                this.store.dispatch("updateSpot", json);
                // remove preview
                this.removeImage();
                this.isLoadingImage = false;
                this.showAlert({
                  active: true,
                  type: "is-success is-light",
                  text: "Finally we got your picture. It's amazing! 🖼️",
                });
              });
              break;

            case 401:
              // logged out in app
              this.$store.commit("authChange", false);
              // place to authentification / login again
              this.$router.push("/");
              break;

            default:
              this.isLoadingNew = false;
              this.showAlert({
                active: true,
                type: "is-danger",
                text: "<strong>Something went wrong. Try again later.</strong><p>We will rush that bug! &#128030;</p>",
              });
          }
        })
        .catch((error) => {
          console.log("Error during upload image: " + error);
        });
    },

    showAlert(settings) {
      return new Promise((resolve) => {
        this.notification = settings;
        setTimeout(() => {
          this.notification.active = false;
          resolve();
        }, 4 * 1000);
      });
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.image.name = files[0].name;
      this.image.data = files[0];

      this.createImage(files[0]);
    },

    createImage(file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.image.preview = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    removeImage: function () {
      this.image.preview = "";
      this.image.data = null;
      this.image.name = "";
    },

    close() {
      const emit = new Event("closeDialog", { bubbles: true });
      document.querySelector(".create-window").dispatchEvent(emit);
    },

    cancel() {
      this.removeImage();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
figure.thumb {
  width: 240px;
  height: 180px;
  border-radius: 3px;
  cursor: pointer;
}

.on-image {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
