import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Map from '../views/Map.vue'
import { authGuard } from "@/auth/authGuard"

const routes = [
  {
    path: '/login',
    name: 'login',
    alias: '/logout',
    component: Login
  },
  {
    path: '/map',
    name: 'map',
    alias: '/',
    component: Map,
    beforeEnter: authGuard
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "list" */ '../views/List.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import(/* webpackChunkName: "manage" */ '../views/OwnSpots.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/spot/:id',
    name: 'spot',
    component: Map,
    props: route => ({
      spotId: parseInt(route.params.id)
    }),
    beforeEnter: authGuard
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
