<template>
  <div class="card">
    <div class="card-content">
      <div class="title is-old"><slot></slot></div>
      <div class="subtitle">{{ author }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WisdomCard",
  props: {
    author: String,
  },
};
</script>

<style lang="scss" scoped>
  .is-old {
    font-family: Marker Felt, fantasy;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  }
</style>